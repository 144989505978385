import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
const firebaseConfig = {
  apiKey: "AIzaSyC16wdnO8Kb1dXbaPDBjdZS4DdO1tKrDbQ",
  authDomain: "gujarati-kids-learning-148e6.firebaseapp.com",
  databaseURL: "https://gujarati-kids-learning-148e6-default-rtdb.firebaseio.com",
  projectId: "gujarati-kids-learning-148e6",
  storageBucket: "gujarati-kids-learning-148e6.firebasestorage.app",
  messagingSenderId: "98784144502",
  appId: "1:98784144502:web:ac33a4446e57d4bb21c357",
  measurementId: "G-CF99796CVN"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);


export const db = getFirestore(app);
export { auth, database };